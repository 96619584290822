import React from 'react';
import ReactDOM from 'react-dom';
import { CardArticle, CardStream, AdContainer } from '@forbes/fbs-components';
import XLRecirc from './XLRecirc';

const renderXLRecirc = (shouldForceRender) => {
	const render = () => {
		const container = document.querySelector('.xl-recirc-ad');
		const speedbumpAd = document.querySelector('.article-speedbump.ad-unit');
		const mobilemnetAd = document.querySelector('.medianet-wrapper.ad-unit');
		const openWebWrapper = document.querySelector('.openWeb-wrapper');
		const isVetted = window.forbes['simple-site']?.isForbesFinds;
		const vettedRecircData = isVetted ? window.forbes['simple-site']?.vettedDataForXlRecirc : undefined;

		if (container) {
			const desktopmnetAd = mobilemnetAd?.closest('.mnet-box');
			const domNode = openWebWrapper || desktopmnetAd || mobilemnetAd || speedbumpAd;
			domNode?.parentNode.insertBefore(container, domNode.nextSibling);
			ReactDOM.render(
				<XLRecirc
					shouldForceRender={shouldForceRender}
					className="xl-recirc-standard"
					isVetted={isVetted}
					showAds={!isVetted}
					vettedRecircData={vettedRecircData}
					CardArticleComponent={CardArticle}
					CardStreamComponent={CardStream}
					AdContainerComponent={AdContainer}
				/>, container);
		}
	};

	if (window.zephrInitialPaywallVerified) {
		render();
	} else {
		window.addEventListener('zephrInitialPaywallVerified', render, { once: true });
	}
};

export default renderXLRecirc;
