// if the article is one of the following templates, we show the sticky ad, even when there is no relativeVideo
const forceStickyAdTemplateTypes = [
	'', // empty templateType for legacy articles, where we should still show sticky ad
	'topline',
	'standard',
	'live',
];

// making these accessible from outside so we can adjust it from environments like VWO
if (typeof window !== 'undefined') {
	window.forbes.forceStickyAdTemplateTypes = forceStickyAdTemplateTypes;
}

const shouldRenderStickyAdOnArticle = ({
	isAdLight,
	adExperience,
	relativeVideo,
	templateType,
	templateSubType,
}) => {
	// For video landers (premium articles) that have the "More videos" component at the bottom, we do not want to show the sticky ad
	const hasRelativeVideo = Object.keys(relativeVideo || {}).length;

	if (isAdLight || templateSubType === 'paidSearch' || templateType === 'forbesAdvisor') {
		return false;
	}

	return forceStickyAdTemplateTypes.includes(templateType) || (adExperience !== 'none' && !hasRelativeVideo);
};

export default shouldRenderStickyAdOnArticle;
